import React, { useState, useEffect } from 'react';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import IRedirectUri from '../../../interfaces/clients/IClientRedirectUri';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useAxios from '../../../hooks/useAxios';

interface INewRedirectDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (redirect: IRedirectUri) => void;
  clientId: string;
}

function NewClientRedirectDialog(props: INewRedirectDialogProps) {
  const { open, onClose, onAdd, clientId } = props;
  const [uri, setUri] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setUri('');
    setErrorMessage('');
  }, [open])

  const handleAddNew = async () => {
    if (uri === '') {
      setErrorMessage('URI is required');
      return;
    }

    const newRedirectUri: IRedirectUri = {
      redirctUri: uri
    }

    onAdd(newRedirectUri);
    onClose();
  }

  return (
    <EnuitDialog
      title='New Client Redirect URI'
      open={open}
      onClose={onClose}
      onOkClick={handleAddNew}
    >
      <Typography>Redirect URI</Typography>
      <TextField
        id='new-client-redirect'
        fullWidth
        size='small'
        value={uri}
        onChange={e => setUri(e.target.value)}
        helperText={errorMessage}
        error={errorMessage !== ''}
      />
    </EnuitDialog>
  )
}

export default NewClientRedirectDialog