import React, { useState, useEffect } from 'react'
import EnuitDialog from '../../../components/dialog/EnuitDialog'
import IClient from '../../../interfaces/clients/IClient';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { sortByClientId } from '../../clients/Clients';
import useAxios from '../../../hooks/useAxios';


interface INewUserClientDialogProps {
  open: boolean;
  onClose: () => void;
  clients: IClient[];
  userId: string;
  onAdd: (client: IClient) => void
}

function NewUserClientDialog(props: INewUserClientDialogProps) {
  const { open, onClose, clients, userId, onAdd } = props;
  const [error, setError] = useState(false);
  const [newClientId, setNewClientId] = useState('');
  const axios = useAxios();

  useEffect(() => {
    setNewClientId('');
    setError(false);
  }, [open])

  function handleSelectionChange(event: SelectChangeEvent<string>) {
    if (error) {
      setError(false);
    }
    setNewClientId(event.target.value);
  }

  const handleNewUserClient = async () => {
    if (newClientId === '') {
      setError(true);
      return;
    }

    try {
      const clientToAdd = clients.find(client => client.clientId === newClientId)!;
      await axios.post<IClient>('admin/User/Client?userId=' + userId, { ...clientToAdd });

      setNewClientId('');
      onAdd(clientToAdd);
      onClose();
    } catch (error) {
      console.log('An error occurred while saving the new user client', error);
    }
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='Link client to user'
      onOkClick={handleNewUserClient}
    >
      <FormControl fullWidth error={error} size='small'>
        <Typography variant='body2'>Client</Typography>
        <Select
          value={newClientId}
          onChange={handleSelectionChange}
        >
          {clients.sort((a, b) => sortByClientId(a, b)).map(client => (
            <MenuItem key={client.clientId} value={client.clientId}>{client.clientId} - {client.clientName}</MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>Required</FormHelperText>}
      </FormControl>
    </EnuitDialog>
  )
}

export default NewUserClientDialog