import React, { useState, useEffect } from 'react';
import EnuitDialog from '../../components/dialog/EnuitDialog';
import IIDentityResource from '../../interfaces/identityResources/IIdentityResource';
import useAxios from '../../hooks/useAxios';
import axiosPackage from 'axios';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';


interface INewIdentityResourceDialogProps {
  open: boolean;
  onClose: () => void;
  updateIdentity: IIDentityResource | undefined;
  onUpdate: (resource: IIDentityResource) => void;
  onAdd: (resource: IIDentityResource) => void;
  onDelete: (resource: IIDentityResource) => void;
}

function NewIdentityResourceDialog(props: INewIdentityResourceDialogProps) {
  const { open, onClose, updateIdentity, onUpdate, onAdd, onDelete } = props;
  const isDialogUpdating = updateIdentity !== undefined;
  const emptyIdentity: IIDentityResource = { name: '', displayName: '', description: '' };
  const [identity, setIdentity] = useState<IIDentityResource>(isDialogUpdating ? { ...updateIdentity } : emptyIdentity);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const axios = useAxios();

  useEffect(() => {
    if (isDialogUpdating) {
      setIdentity(updateIdentity);
    } else {
      setIdentity(emptyIdentity);
    }
    setNameErrorMessage('');
  }, [open]);

  function handleUpdate(updates: Partial<IIDentityResource>) {
    const updatedValue: IIDentityResource = {
      ...identity,
      ...updates
    }

    setIdentity(updatedValue);
  }

  const handleDialogSubmit = async () => {
    if (identity.name === '') {
      setNameErrorMessage('Name is required');
      return;
    }

    try {

      const newIdentity: IIDentityResource = {
        name: identity.name,
        description: identity.description,
        displayName: identity.displayName
      }

      if (isDialogUpdating) {
        const updatedIdentity = { ...updateIdentity, ...newIdentity }
        const response = await axios.put<IIDentityResource>(`admin/IdentityResource?key=${identity.name}`, { ...updatedIdentity });
        onUpdate(response.data);
      } else {
        const response = await axios.post<IIDentityResource>('admin/IdentityResource', { ...newIdentity });
        onAdd(response.data);
      }
      onClose();
    } catch (error) {
      handleError(error);
    }
  }

  function handleError(error: any) {
    if (axiosPackage.isAxiosError(error)) {
      if (error.response?.status === 500 && (error.response?.data as string).includes('IX_IdentityResources_Name')) {
        setNameErrorMessage('Cannot insert duplicate names. Change the name and try again.');
      } else {
        console.log('an unhandled axios error has occurred while saving the data', error);
      }
    } else {
      console.log('an unhandled error has occurred while saving the data', error);
    }
  }

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`admin/IdentityResource?key=${updateIdentity!.name}`);
      onDelete(updateIdentity!);
      onClose();
    } catch (error) {
      console.log('An error occurred while trying to delete the identity resource.', error);
    }
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='New Identity Resource'
      onOkClick={handleDialogSubmit}
      showDelete={isDialogUpdating}
      onDeleteClick={handleDelete}
    >
      <div id='dialog-content'>
        <div>
          <Typography variant='body2'>Name</Typography>
          <TextField
            size='small'
            fullWidth
            value={identity.name ?? ''}
            onChange={(e) => handleUpdate({ name: e.target.value })}
            error={nameErrorMessage !== ''}
            helperText={nameErrorMessage}
            disabled={isDialogUpdating}
          />
        </div>
        <div>
          <Typography variant='body2'>Display Name</Typography>
          <TextField
            size='small'
            fullWidth
            value={identity.displayName ?? ''}
            onChange={(e) => handleUpdate({ displayName: e.target.value })}
            disabled={isDialogUpdating}
          />
        </div>
        <div>
          <Typography variant='body2'>Description</Typography>
          <TextField
            size='small'
            fullWidth
            value={identity.description ?? ''}
            onChange={(e) => handleUpdate({ description: e.target.value })}
          />
        </div>
      </div>
    </EnuitDialog>
  )
}

export default NewIdentityResourceDialog