import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import IApiResource from "../../interfaces/apiResources/IApiResource";
import IClient, { IClientProvider } from "../../interfaces/clients/IClient";
import { IGrantType } from "../../interfaces/clients/IClientGrantType";
import ILogoutUri from "../../interfaces/clients/IClientLogoutUri";
import IRedirectUri, { IPostRedirectUri } from "../../interfaces/clients/IClientRedirectUri";

export default class ClientManagementSaveHandler {
  axios: AxiosInstance;
  clientId: string;
  /**
   *
   */
  constructor(axios: AxiosInstance, clientId: string) {
    this.axios = axios;
    this.clientId = clientId;
  }

  saveClient = async (client: IClient) => {
    try {
      await this.axios.put('admin/Client?clientId=' + this.clientId, { ...client });
    } catch (error) {
      console.log('An error occurred while trying to update the client', error);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }

  saveScopes = async (scopes: IApiResource[], originalScopes: IApiResource[]) => {
    try {
      const newlyAddedScopes = scopes.filter(scope => !originalScopes.map(a => a.name).includes(scope.name));
      console.log('Found some newly added scopes', newlyAddedScopes);
      for (const scope of newlyAddedScopes) {
        await this.axios.post('admin/Client/Scope', {clientId: this.clientId, name: scope.name});
      }
    } catch (error) {
      console.log('An error occurred while adding a client scope.', error)
      return Promise.reject(error);
    }

    try {
      const newlyDeletedScopes = originalScopes.filter(scope => !scopes.map(a => a.name).includes(scope.name));
      for (const scope of newlyDeletedScopes) {
        await this.axios.delete(`admin/Client/Scope?clientid=${this.clientId}&scope=${scope.name}`);
      }
    } catch (error) {
      console.log('An error occurred while deleting a client scope.', error);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }

  saveGrants = async (grants: IGrantType, originalGrants: IGrantType) => {
    try {
      if (grants.authorization_code && !originalGrants.authorization_code) {
        await this.axios.post('admin/Client/GrantType', {clientId: this.clientId, name: 'authorization_code'});
      } else if (!grants.authorization_code && originalGrants.authorization_code) {
        await this.axios.delete(`admin/Client/GrantType?clientid=${this.clientId}&grantType=authorization_code`);
      }

      if (grants.client_credentials && !originalGrants.client_credentials) {
        await this.axios.post('admin/Client/GrantType', {clientId: this.clientId, name: 'client_credentials'});
      } else if (!grants.client_credentials && originalGrants.client_credentials) {
        await this.axios.delete(`admin/Client/GrantType?clientid=${this.clientId}&grantType=client_credentials`);
      }

      if (grants.password && !originalGrants.password) {
        await this.axios.post('admin/Client/GrantType', {clientId: this.clientId, name: 'password'});
      } else if (!grants.password && originalGrants.password) {
        await this.axios.delete(`admin/Client/GrantType?clientid=${this.clientId}&grantType=password`);
      }
    } catch (error) {
      console.log('An error occurred while updating the client grant types');
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }

  saveRedirects = async (redirects: IRedirectUri[], originalRedirects: IRedirectUri[]) => {
    const originalRedirectNames = originalRedirects.map(uri => uri.redirctUri);
    const redirectNames = redirects.map(uri => uri.redirctUri);

    try {
      const newlyAddedRedirects = redirects.filter(uri => !originalRedirectNames.includes(uri.redirctUri));
      for (const redirect of newlyAddedRedirects) {
        const newRedirect: IPostRedirectUri = {
          name: redirect.redirctUri,
          clientId: this.clientId
        }

        await this.axios.post('admin/Client/RedirectUri', { ...newRedirect });
      }

      
    } catch (error) {
      console.log('An error occurred while saving the newly added client redirects', error);
      return Promise.reject(error);
    }

    try {
      const newlyDeletedRedirects = originalRedirects.filter(uri => !redirectNames.includes(uri.redirctUri));
      for (const redirect of newlyDeletedRedirects) {
        const deleteRedirect: IPostRedirectUri = {
          name: redirect.redirctUri,
          clientId: this.clientId
        }

        await this.axios.delete('admin/Client/RedirectUri', { data: { ...deleteRedirect } });
      }
    } catch (error) {
      console.log('An error occurred while saving the newly deleted client redirects', error);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }

  saveLogouts = async (logouts: ILogoutUri[], originalLogouts: ILogoutUri[]) => {
    const logoutNames = logouts.map(a => a.stringValue);
    const originalLogoutNames = originalLogouts.map(a => a.stringValue);

    try {
      const newlyAddedLogouts = logouts.filter(logout => !originalLogoutNames.includes(logout.stringValue));
      for (const logout of newlyAddedLogouts) {
        const newLogout: IPostRedirectUri = {
          name: logout.stringValue,
          clientId: this.clientId
        }

        await this.axios.post('admin/Client/PostLogoutRedirectUri', { ...newLogout });
      }
    } catch (error) {
      console.log('An error occurred while saving the newly added post logout uris', error);
      return Promise.reject(error);
    }

    try {
      const newlyDeletedLogouts = originalLogouts.filter(logout => !logoutNames.includes(logout.stringValue));
      for (const logout of newlyDeletedLogouts) {
        const deletedLogout: IPostRedirectUri = {
          name: logout.stringValue,
          clientId: this.clientId
        }

        await this.axios.delete('admin/Client/PostLogoutRedirectUri', { data: { ...deletedLogout } });
      }
    } catch (error) {
      console.log('An error occurred while saving the newly deleted post logout uris', error);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }

  saveCORS = async (cors: ILogoutUri[], originalCors: ILogoutUri[]) => {
    const corsNames = cors.map(a => a.stringValue);
    const originalCorsNames = originalCors.map(a => a.stringValue);

    try {
      const newlyAddedCors = cors.filter(a => !originalCorsNames.includes(a.stringValue));
      for (const cor of newlyAddedCors) {
        const newCors: IPostRedirectUri = {
          name: cor.stringValue,
          clientId: this.clientId
        }

        await this.axios.post('admin/Client/Cors', { ...newCors });
      }
    } catch (error) {
      console.log('An error occurred while saving the newly added cors origins', error);
      return Promise.reject(error);
    }

    try {
      const newlyDeletedCors = originalCors.filter(a => !corsNames.includes(a.stringValue));
      for (const cor of newlyDeletedCors) {
        const deleteCors: IPostRedirectUri = {
          name: cor.stringValue,
          clientId: this.clientId
        }

        await this.axios.delete('admin/Client/Cors', { data: { ...deleteCors } });
      }
    } catch (error) {
      console.log('An error occurred while saving the newly deleted cors origins', error);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }

  saveProviders = async (providers: IClientProvider[]) => {
    try {
      await this.axios.put('admin/Client/IdentityProvider?clientId='+this.clientId, providers)
    } catch (error) {
      console.log('An error occurred while saving the providers', error);
      return Promise.reject(error);
    }

    return Promise.resolve(true);
  }
}