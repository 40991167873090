import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { generateQueryParams, IUserSearchParams, userIdQueryParamKey } from '../../hooks/useQueryParams';
import useAxios from '../../hooks/useAxios';

interface AppHeaderProps {
  onNavigationToggle: () => void;
  onThemeModeToggle: () => void;
  themeMode: 'light' | 'dark';
}

type AppVersionResponse = {
  details: [
    {
      assemblyName: string;
      version: string;
    }
  ]
}

export default function AppHeader(props: AppHeaderProps) {
  const { onNavigationToggle, onThemeModeToggle, themeMode } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [version, setVersion] = React.useState('');
  const auth = useAuth();
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const axios = useAxios();

  React.useEffect(() => {
    loadAppVersion();
  }, [])

  const loadAppVersion = async () => {
    try {
      const response = await axios.get<AppVersionResponse>('application/version');
      setVersion((response.data.details[0]?.version) ?? '');
    } catch (error) {
      console.log('An error occurred while loading the app version')
    }
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToSelectedUser = async () => {
    const queryParams = generateQueryParams<IUserSearchParams>(userIdQueryParamKey, {selectedUserId: auth.user?.profile.sub ?? '', filterClientId: ''});
    navigate('/users?' + queryParams.toString());
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { navigateToSelectedUser(); handleMenuClose(); }}>Profile</MenuItem>
      <MenuItem onClick={() => { auth.signoutRedirect(); handleMenuClose(); }}>Log out</MenuItem>
      <Typography sx={{p: '6px 16px', opacity: '0.5'}}>Version: {version}</Typography>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ paddingLeft: `${theme.spacing(2)} !important` }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={onNavigationToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Entrade Authentication Service
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={onThemeModeToggle}
              color="inherit"
            >
              {
                themeMode === 'light'
                  ? <DarkModeIcon />
                  : <LightModeIcon />
              }
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
