import React, { useEffect, useState } from 'react';
import EnuitDialog from '../../components/dialog/EnuitDialog';
import IUser from '../../interfaces/users/IUser';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Checkbox, Chip, FormControlLabel} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IUserClaim from '../../interfaces/users/IUserClaim';
import useAxios from '../../hooks/useAxios';

type UserDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  user: IUser | undefined;
}

function UserDetailsDialog(props: UserDetailsDialogProps) {
  const { open, onClose, user } = props;
  const [userClaims, setUserClaims] = useState<IUserClaim[]>([]);
  const axios = useAxios();

  useEffect(() => {
    loadUserClaims();
  }, [open]);

  const loadUserClaims = async () => {
    if (!user) {
      setUserClaims([]);
      return;
    }

    try {
      const response = await axios.get<IUserClaim[]>('admin/User/Claim?userId='+user.id);
      setUserClaims(response.data);
    } catch (error) {
      console.log('An error occurred while loading the user claims', error);
      setUserClaims([]);
    }
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='User Details'
    >
      <Stack direction='column' spacing={2}>
        <Box>
          <Typography variant='body2'>Name</Typography>
          <TextField value={user?.name ?? 'N/A'} fullWidth size='small' />
        </Box>
        <Box>
          <Typography variant='body2'>Username</Typography>
          <TextField value={user?.userName ?? 'N/A'} fullWidth size='small' />
        </Box>
        <Box>
          <Typography variant='body2'>Email</Typography>
          <TextField value={user?.email ?? 'N/A'} fullWidth size='small' />
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Stack direction='row' spacing={2}>
            <FormControlLabel
              label='External User'
              control={
                <Checkbox checked={!user?.isInternal ?? false} size='small' />
              }
            />
            <FormControlLabel
              label='Account Locked Out'
              control={
                <Checkbox checked={user?.isLockedOut ?? false} size='small' />
              }
            />
          </Stack>
        </Box>
        <Typography variant='subtitle2'>User Claims</Typography>
        <Stack direction='row' spacing={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            userClaims.map(claim => (
              <Chip key={claim.type + claim.value} label={`${claim.type} - ${claim.value}`} sx={{mb: 1}} />
            ))
          }
        </Stack>
      </Stack>
    </EnuitDialog>
  )
}

export default UserDetailsDialog