import React from 'react';
import { RouteObject } from "react-router-dom";
// Icons
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ApiIcon from '@mui/icons-material/Api';
import KeyIcon from '@mui/icons-material/Key';
import ListAltIcon from '@mui/icons-material/ListAlt';
// Pages
import ApiResources from "./pages/apiResources/ApiResources";
import IdentityResources from "./pages/identityResources/IdentityResources";
import Clients from "./pages/clients/Clients";
import Roles from "./pages/roles/Roles";
import ClaimTypes from "./pages/claimTypes/ClaimTypes";
import Users from "./pages/users/Users";
import Profile from "./pages/profile/Profile";
import ClientManagement from './pages/clients/ClientManagement';
import Unauthorized from './pages/unauthorized/Unauthorized';
import UserRoles from './pages/roles/UserRoles';

export interface IRoute extends RouteObject {
  dataSourceId: number;
  text: string;
  icon?: React.ReactNode;
  visibleInNavBar: boolean;
}

const routes: IRoute[] = [
  {
    path: '*',
    element: <div>This page does not exist</div>,
    dataSourceId: 2,
    text: 'Error Page',
    visibleInNavBar: false
  },
  {
    path: 'profile',
    element: <Profile />,
    dataSourceId: 3,
    text: '',
    visibleInNavBar: false
  },
  {
    path: '/',
    element: <ApiResources />,
    dataSourceId: 4,
    text: 'API Resources',
    icon: <ApiIcon />,
    visibleInNavBar: true
  },
  {
    path: 'identity-resources',
    element: <IdentityResources />,
    dataSourceId: 5,
    text: 'Identity Resources',
    icon: <FingerprintIcon />,
    visibleInNavBar: true
  },
  {
    path: 'clients',
    element: <Clients />,
    dataSourceId: 6,
    text: 'Clients',
    icon: <PersonIcon />,
    visibleInNavBar: true
  },
  {
    path: 'roles',
    element: <Roles />,
    dataSourceId: 7,
    text: 'Roles',
    icon: <KeyIcon />,
    visibleInNavBar: true
  },
  {
    path: 'claim-types',
    element: <ClaimTypes />,
    dataSourceId: 8,
    text: 'Claim Types',
    icon: <ListAltIcon />,
    visibleInNavBar: true
  },
  {
    path: 'users',
    element: <Users />,
    dataSourceId: 9,
    text: 'Users',
    icon: <PeopleIcon />,
    visibleInNavBar: true
  },
  {
    path: 'clients/:clientId',
    element: <ClientManagement />,
    dataSourceId: 10,
    text: '',
    visibleInNavBar: false
  },
  {
    path: 'unauthorized',
    element: <Unauthorized />,
    dataSourceId: 11,
    text: '',
    visibleInNavBar: false
  },
  {
    path: 'roles/:name',
    element: <UserRoles />,
    dataSourceId: 12,
    text: '',
    visibleInNavBar: false
  }
]

function sortByText(a: IRoute, b: IRoute) {
  if (a.text.toLocaleLowerCase() < b.text.toLocaleLowerCase()) {
    return -1;
  } else if (a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase()) {
    return 1;
  }
  return 0;
}

export function getToolbarRoutes() {
  return (routes.sort((a,b) => sortByText(a,b)).filter(route => route.visibleInNavBar));
}

export default routes;