import { AxiosInstance } from "axios";
import { AuthContextProps } from "react-oidc-context";
import IApiResource from "../../interfaces/apiResources/IApiResource";
import IClient, { IClientProvider, IClientSecret } from "../../interfaces/clients/IClient";
import IClientGrantType, { IGrantType } from "../../interfaces/clients/IClientGrantType";
import { ILogoutURIResponse } from "../../interfaces/clients/IClientLogoutUri";
import { IRedirectUriResponse } from "../../interfaces/clients/IClientRedirectUri";


export default class ClientManagementLoadHandler {
  axios: AxiosInstance;
  clientId: string;


  constructor(axios: AxiosInstance, clientId: string) {
    this.axios = axios;
    this.clientId = clientId; 
  }

  loadClient = async () => {
    try {
      const response = await this.axios.get<IClient>('admin/Client/'+this.clientId);
      return response.data;
    } catch (error) {
      console.log('An error occurred while loading the client.', error);
    }
  }
  
  loadPossibleScopes = async () => {
    try {
      const possibleScopes = await this.axios.get<IApiResource[]>('admin/Client/Scope');
      const clientScopes = await this.axios.get<IApiResource[]>('admin/Client/Scope/' + this.clientId);
      const scopeNames = clientScopes.data.map(a => a.name);
      const scopes = possibleScopes.data.filter(scope => scopeNames.includes(scope.name));
  
      return {possibleScopes: possibleScopes.data, clientScopes: scopes};
    } catch (error) {
      console.log('An error occurred while loading the possible scopes.', error);
    }
  }
  
  loadClientGrantTypes = async () => {
    try {
      const response = await this.axios.get<IClientGrantType[]>('admin/Client/GrantType?id=' + this.clientId);
      const loadedGrantTypes = response.data.map(grantType => grantType.grantType);
  
      let newGrantType: IGrantType = {
        'authorization_code': false,
        'client_credentials': false,
        'password': false
      }
  
      for (const grantType of loadedGrantTypes) {
        newGrantType[grantType as keyof IGrantType] = true;
      }

      return newGrantType;
    } catch (error) {
      console.log('An error has occurred while loading the client grant types', error);
    }
  }
  
  loadClientRedirects = async () => {
    try {
      const response = await this.axios.get<IRedirectUriResponse>('admin/Client/RedirectUri?clientId=' + this.clientId);
      return response.data.data;
    } catch (error) {
      console.log('An error has occurred while loading the client redirects', error);
    }
  }
  
  loadPostLogoutRedirects = async () => {
    try {
      const response = await this.axios.get<ILogoutURIResponse>('admin/Client/PostLogoutRedirectUri?clientid=' + this.clientId);
      return response.data.data;
    } catch (error) {
      console.log('An error occurred while loading the post logout redirects.', error);
    }
  }
  
  loadAllowedCors = async () => {
    try {
      const response = await this.axios.get<ILogoutURIResponse>('admin/Client/Cors?id=' + this.clientId);
      return response.data.data;
    } catch (error) {
      console.log('An error occurred while loading the allowed cors origins.', error);
    }
  }

  loadSecret = async () => {
    try {
      const response = await this.axios.get<IClientSecret>('admin/Client/Secrets?clientId='+ this.clientId);
      return response.data.hasSecret;
    } catch (error) {
      console.log('An error occurred while loading the client secret.', error);
    }
  }

  loadClientProviders = async () => {
    try {
      const response = await this.axios.get<IClientProvider[]>('admin/Client/IdentityProvider?clientId=' + this.clientId);
      return response.data;
    } catch (error) {
      console.log('An error occurred while loading the client providers', error);
    }
  }
}
