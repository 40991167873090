import React from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Stack from '@mui/material/Stack';


interface IUserInfoCardProps {
  primaryKey: string;
  primaryText: string;
  secondaryText: string;
  onDelete: (id: string) => void;
}

function UserInfoCard(props: IUserInfoCardProps) {
  const { primaryKey, primaryText, secondaryText, onDelete } = props;
  return (
    <Paper elevation={1} sx={{p: 1}}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='column'>
          <Typography variant='subtitle2'>{primaryText}</Typography>
          <Typography variant='body2'>{secondaryText}</Typography>
        </Stack>
        {(primaryKey !== 'id') && <IconButton onClick={() => onDelete(primaryKey)}>
          <DeleteForeverIcon />
        </IconButton>}
      </Stack>
    </Paper>
  )
}

export default UserInfoCard