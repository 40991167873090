import React, { useEffect, useState } from 'react'
import EnuitDialog from '../../../components/dialog/EnuitDialog'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import IRole from '../../../interfaces/roles/IRole';
import useAxios from '../../../hooks/useAxios';

interface INewUserRoleDialogProps {
  open: boolean;
  onClose: () => void;
  roles: IRole[];
  userId: string;
  onAdd: (newUserRole: IRole) => void;
}

function NewUserRoleDialog(props: INewUserRoleDialogProps) {
  const { open, onClose, roles, userId, onAdd } = props;
  const [newRole, setNewRole] = useState('');
  const [error, setError] = useState(false);
  const axios = useAxios();

  const handleNewUserRole = async () => {
    if (newRole === '') {
      setError(true);
      return;
    }

    try {
      const newUserRole = roles.find(role => role.name === newRole)!;
      await axios.post(`admin/User/Role?userId=${userId}&roleName=${newUserRole.name}`);

      setNewRole('');
      onAdd(newUserRole);
      onClose();
    } catch (error) {
      console.log('An error occurred while adding a new user role', error);
    }
  }

  function handleSelectionChange(e: SelectChangeEvent<string>) {
    if (error) {
      setError(false);
    }
    setNewRole(e.target.value)
  }

  useEffect(() => {
    setNewRole('');
    setError(false);
  }, [open])

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='New User Role'
      onOkClick={handleNewUserRole}
    >
      <FormControl fullWidth error={error} size='small'>
        <Typography variant='body2'>Role</Typography>
        <Select
          value={newRole}
          onChange={handleSelectionChange}
        >
          {roles.map(role => (
            <MenuItem key={role.name} value={role.name}>{role.name} - {role.description}</MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>Required</FormHelperText>}
      </FormControl>
    </EnuitDialog>
  )
}

export default NewUserRoleDialog