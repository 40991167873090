import React, { useState, useEffect, useMemo } from 'react'
import useAxios from '../../hooks/useAxios';
import './users.scss';

import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
  DragDropProvider,
  TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui';
// MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Lock from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// Interface
import IUser from '../../interfaces/users/IUser';
import IUserClient, { IUserClientResponse } from '../../interfaces/users/IUserClient';
// Other
import SingleUser from './SingleUser';
import NewUserDialog from './dialogs/NewUserDialog';
import { IUserSearchParams, useQueryParams, userIdQueryParamKey } from '../../hooks/useQueryParams';
import Searchfield from '../../components/Searchfield/Searchfield';
import ax from 'axios';
import { useNavigate } from 'react-router-dom';
import UserClientsDialog from './dialogs/UserClientsDialog';
import Box from '@mui/material/Box';
import { useAuth } from 'react-oidc-context';

function Users() {

  const [users, setUsers] = useState<IUser[]>([]);
  const [showLockedUsers, setShowLockedUsers] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
  const [filterClientId, setFilterClientId] = useState<string | null>(null);
  const [possibleClients, setPossibleClients] = useState<IUserClient[]>([]);
  const [userClientsDialogOpen, setUserClientsDialogOpen] = useState(false);
  let [searchParams, setSearchParams] = useQueryParams<IUserSearchParams>(userIdQueryParamKey);
  const axios = useAxios();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await axios.get<IUser[]>('admin/User');
      setUsers(response.data);

      const userClientLinkResponse = await axios.get<IUserClientResponse>('admin/Client/Users/Groups');
      setPossibleClients(userClientLinkResponse.data.data);

      if (searchParams?.selectedUserId ?? '' !== '') {
        const selectedUser = response.data.find(user => user.id === searchParams!.selectedUserId)
        setSelectedUser(selectedUser);
      }

      if (searchParams?.filterClientId ?? '' !== '') {
        setFilterClientId(searchParams?.filterClientId ?? null);
      }
    } catch (error) {
      if (ax.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
          return;
        }
      }
      console.log('An error has occurred while loading the users.', error);
    }
  }

  //To enable clicking the profile button while viewing the users grid
  useEffect(() => {
    if (searchParams?.selectedUserId !== '' && selectedUser === undefined) {
      setSelectedUser(users.find(user => user.id === auth.user?.profile.sub));
    } else if (!searchParams?.selectedUserId) {
      setSelectedUser(undefined);
    }
  }, [searchParams])

  const NameFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
    const { value, row, column } = props;
    if ((row as IUser).isLockedOut) {
      return value + ' (Locked Out)'
    } else {
      return value;
    }
  }

  const NameProvider = (props: any) => {
    return (
      <DataTypeProvider
        formatterComponent={NameFormatter}
        {...props}
      />
    )
  }

  useEffect(() => {
    if (selectedUser !== undefined) {
      const searchParams: IUserSearchParams = { selectedUserId: selectedUser.id, filterClientId: filterClientId ?? '' }
      setSearchParams(searchParams, { replace: true });
    } else {
      setSearchParams({ selectedUserId: '', filterClientId: filterClientId ?? '' }, { replace: true });
    }
  }, [selectedUser, filterClientId]);

  const columns = [
    { name: 'name', title: 'Name' },
    { name: 'userName', title: 'User Name' },
    { name: 'email', title: 'Email' },
    {name: 'providerDisplayName', title: 'Provider'}
  ];

  const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.users-table`]: {
      '& tbody tr:nth-of-type(odd)': {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
      }
    },
  }));

  const TableComponent = (props: any) => (
    <StyledTable
      {...props}
      className={'users-table'}
    />
  );

  const TableRow = (props: Table.DataRowProps) => {
    const { row, tableRow, children, forwardedRef, ...restProps } = props;
    return (<Table.Row
      {...restProps}
      sx={{
        // cursor: 'pointer'
        opacity: row.isLockedOut ? 0.5 : 1,

        '.view-profile-button': {
          opacity: 0
        },
        '&:hover': {
          '.view-profile-button': {
            opacity: 1
          }
        }
      }}
      row={row}
      tableRow={tableRow}
    >
      <TableCell>
        {row.isLockedOut
          ?
          <IconButton onClick={() => handleUpdateLocked(row)} disabled={row.id === auth.user?.profile.sub }>
            <Lock />
          </IconButton>
          :
          <IconButton onClick={() => handleUpdateLocked(row)} disabled={row.id === auth.user?.profile.sub }>
            <LockOpenIcon />
          </IconButton>
        }
      </TableCell>
      {children}
      <TableCell align='right' size='small'>
        <Button className='view-profile-button' variant='outlined' sx={{ marginRight: '80px' }} endIcon={<ArrowForwardIcon />} onClick={() => setSelectedUser(row)}>User Profile</Button>
      </TableCell>
    </Table.Row>);
  };

  const handleUpdateLocked = async (user: IUser) => {
    try {
      if (user.isLockedOut) {
        await axios.put('admin/User/UnLock?userId=' + user.id, { ...user });
      } else {
        await axios.put('admin/User/Lock?userId=' + user.id, { ...user });
      }

      const newState = users.map(a => a.id === user.id ? { ...user, isLockedOut: !user.isLockedOut } : a);
      setUsers(newState);
    } catch (error) {
      console.log('There was a problem updating the locked value', error);
    }
  }


  const headerRow = (props: Table.RowProps) => {
    const { tableRow, children, ...restProps } = props;

    //Empty table cells are to account for lock user button and view profile button
    return (
      <TableHeaderRow.Row tableRow={tableRow}>
        <TableCell width={'100px'}></TableCell>
        {children}
        <TableCell></TableCell>
      </TableHeaderRow.Row>
    );
  }

  const ToolbarRootBase = (props: Toolbar.RootProps) => {
    const { children, ...rest } = props
    return (
      <Toolbar.Root {...rest}>
        <Stack justifyContent='space-between' direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            {children}
            <Autocomplete
              renderInput={(params) => <TextField {...params} placeholder="Select a client..." />}
              options={getPossibleClients}
              sx={{ width: 200 }}
              size='small'
              value={filterClientId}
              onChange={(e, value) => setFilterClientId(value)}
            />
            <FormControlLabel
              label='Show Locked Users'
              control={
                <Checkbox
                  checked={showLockedUsers}
                  onChange={(e) => setShowLockedUsers(e.target.checked)}
                />
              }
            />
          </Stack>
          <Stack direction='row' spacing={1}>
            {filterClientId !== null && 
              <Button onClick={() => setUserClientsDialogOpen(true)} startIcon={<AddIcon />} variant='outlined'>Add users to client</Button>
            }
            <Button startIcon={<AddIcon />} onClick={() => setOpen(true)} variant='contained'>New User</Button>
          </Stack>
        </Stack>
      </Toolbar.Root>
    );
  }

  const CustomSearch = (props: SearchPanel.InputProps) => {
    const { value, onValueChange, ...rest } = props;
    return (
      <Searchfield value={value} onChange={(e) => onValueChange(e.target.value)} />
    );
  }

  const handleUpdateUser = (updatedUser: IUser) => {
    console.log('handing updating the user in the users overview');
    setUsers(prev => ([...prev].map(a => a.id === updatedUser.id ? updatedUser : a)));
    setSelectedUser(updatedUser);
  }

  const handleAddUser = (newUser: IUser) => {
    setUsers(prev => [...prev].concat(newUser));
    setSelectedUser(newUser);
  }

  const getPossibleClients = useMemo(() => {
    const clientNames = possibleClients.map(client => client.client);
    const distinctClientNames = clientNames.filter((v, i, a) => a.indexOf(v) === i);
    return distinctClientNames;
  }, [possibleClients]);

  const getVisibleUsers = useMemo(() => {
    if (filterClientId !== null) {
      const usersForThisClient = possibleClients.filter(a => a.client === filterClientId).map(a => a.userId);
      const usersToShow = showLockedUsers
        ?
        users.filter(user => usersForThisClient.includes(user.id))
        :
        users.filter(user => usersForThisClient.includes(user.id) && !user.isLockedOut);
      return usersToShow;
    } else {
      return showLockedUsers ? users : users.filter(user => !user.isLockedOut);
    }
  }, [users, filterClientId, showLockedUsers, possibleClients]);

  const handleUserClientsSave = async (usersForSelectedClient: IUser[]) => {
    const userClientsExceptCurrentClient = possibleClients.filter(userClient => userClient.client !== filterClientId!);
    const updatedUserClientsForCurrentClient: IUserClient[] = usersForSelectedClient.map(user => ({ userId: user.id, client: filterClientId! }))
    const newUserClients = [...userClientsExceptCurrentClient].concat(updatedUserClientsForCurrentClient);
    setPossibleClients(newUserClients);
  }

  return (
    <div>
      <NewUserDialog open={open} onClose={() => setOpen(false)} onAdd={handleAddUser} />
      <UserClientsDialog
        open={userClientsDialogOpen}
        onClose={() => setUserClientsDialogOpen(false)}
        clientId={filterClientId!}
        possibleUsers={users.filter(user => !user.isLockedOut)}
        existingUsers={getVisibleUsers}
        onSave={handleUserClientsSave}
      />
      <div className={selectedUser === undefined ? 'hide' : ''}>
        <Button sx={{ marginBottom: '8px' }} startIcon={<ArrowBackIcon />} onClick={() => setSelectedUser(undefined)}>Back to users</Button>
        {selectedUser !== undefined && <SingleUser user={selectedUser} onUpdateUser={handleUpdateUser} />}
      </div>
      <div className={selectedUser === undefined ? '' : 'hide'}>
        <Box mb={2}>
          <Typography variant='subtitle1'>Users</Typography>
        </Box>
        <div>
          <Paper>
            <Grid
              rows={getVisibleUsers}
              columns={columns}
              getRowId={(row: IUser) => row.id}
            >
              <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
              <SearchState />
              <PagingState defaultCurrentPage={0} defaultPageSize={10} />
              <IntegratedSorting />
              <IntegratedFiltering />
              <IntegratedPaging />
              <DragDropProvider />
              <NameProvider for={['name']} />
              <Table tableComponent={TableComponent} rowComponent={TableRow} />
              <TableColumnReordering defaultOrder={['name', 'userName', 'email', 'providerDisplayName']} />
              <TableHeaderRow rowComponent={headerRow} showSortingControls />
              <Toolbar rootComponent={ToolbarRootBase} />
              <SearchPanel inputComponent={CustomSearch} />
              <PagingPanel pageSizes={[5, 10, 15, 20]} />
            </Grid>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default Users