import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function NotAuthorized() {
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Typography variant='h5'>Users without admin privileges are not authorized to view this page.</Typography>
    </Box>
  )
}

export default NotAuthorized