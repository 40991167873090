import React, { useState, useEffect } from 'react'
import EnuitDialog from '../../components/dialog/EnuitDialog'
import IClient from '../../interfaces/clients/IClient';
import useAxios from '../../hooks/useAxios';
import ClientDetails from './components/ClientDetails';
import ax from 'axios';
import { emptyClient } from './ClientManagement';

interface INewClientDialogProps {
  open: boolean;
  onClose: () => void;
  addClient: (client: IClient) => void;
}

function NewClientDialog(props: INewClientDialogProps) {
  const { open, onClose, addClient } = props;
  const [newClient, setNewClient] = useState<IClient>(emptyClient);
  const [idErrorMessage, setIdErrorMessage] = useState('');
  const axios = useAxios();

  useEffect(() => {
    setNewClient(emptyClient);
    setIdErrorMessage('');
  }, [open]);

  const handleAddNewClient = async () => {
    if (newClient.clientId === '') {
      setIdErrorMessage('Id is required');
      return;
    }

    try {
      await axios.post('admin/Client', {...newClient});
      addClient(newClient);
      onClose();
    } catch (error) {
      if (ax.isAxiosError(error) && (error.response?.data as string).includes('IX_Clients_ClientId')) {
        setIdErrorMessage('A client with this Id already exists');
      } else {
        console.log('An error occurred while saving the new client', error);
      }
    }
  }

  function handleUpdate(updates: Partial<IClient>) {
    setNewClient(prev => { return { ...prev, ...updates } });
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='New Client'
      onOkClick={handleAddNewClient}
    >
      <ClientDetails areDetailsUpdating={false} client={newClient} handleUpdate={handleUpdate} idErrorMessage={idErrorMessage} />
    </EnuitDialog>
  )
}

export default NewClientDialog