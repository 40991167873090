import React, { useState, useEffect } from 'react';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ILogoutUri from '../../../interfaces/clients/IClientLogoutUri';

interface INewClientAllowedCorsDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (redirect: ILogoutUri) => void;
  clientId: string;
}

function NewClientAllowedCorsDialog(props: INewClientAllowedCorsDialogProps) {
  const { open, onClose, onAdd } = props;
  const [uri, setUri] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setUri('');
    setErrorMessage('');
  }, [open])

  const handleAddNew = async () => {
    if (uri === '') {
      setErrorMessage('CORS is required');
      return;
    }

    const newCors: ILogoutUri = {
      stringValue: uri
    }

    onAdd(newCors);
    onClose();
  }

  return (
    <EnuitDialog
      title='New Client Allowed CORS'
      open={open}
      onClose={onClose}
      onOkClick={handleAddNew}
    >
      <Typography>Allowed CORS Origin</Typography>
      <TextField
        id='new-client-redirect'
        fullWidth
        size='small'
        value={uri}
        onChange={e => setUri(e.target.value)}
        helperText={errorMessage}
        error={errorMessage !== ''}
      />
    </EnuitDialog>
  )
}

export default NewClientAllowedCorsDialog