import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
// import './App.scss';
import { useAuth } from "react-oidc-context";
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import Cookies from "universal-cookie";
import routes from './routes';
import Nav from './components/Navigation/Nav';
import AppHeader from './components/AppHeader/AppHeader';
import EntradeTheme from './theme/theme';
import themes from "devextreme/ui/themes";
import { useCookies } from 'react-cookie';

type ThemeModeOptions = 'light' | 'dark';

function App() {
  const [cookies, setCookies] = useCookies(['theme', 'open']);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [themeMode, setThemeMode] = useState<ThemeModeOptions>(cookies.theme !== undefined ? cookies.theme : 'light');
  const theme = useTheme(); 

  
  function RoutesElement(){
    return useRoutes(routes);
  }

  const handleNavigationToggle = () => {
    setCookies('open', !sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  }

  const handleThemeModeToggle = () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
    themes.current(themeMode === 'light' ? 'generic.dark' : 'generic.light');
    setCookies('theme', themeMode === 'light' ? 'dark' : 'light');
  }

  return (
    <div id="app">
      <EntradeTheme themeMode={themeMode}>
        <Router>
          <AppHeader onNavigationToggle={handleNavigationToggle} themeMode={themeMode} onThemeModeToggle={handleThemeModeToggle} />
          <Box sx={{ display: 'flex', height: `calc(100vh - ${theme.spacing(8)})`, overflow: 'hidden' }}>
            <Nav expanded={sidebarOpen}/>
            <Box sx={{ overflow: 'auto', flex: 1, padding: `${theme.spacing(3)}`, backgroundColor: themeMode === 'light' ? '#F6F7FA' : '#121212'}}>
              <RoutesElement />
            </Box>
          </Box>
        </Router>
      </EntradeTheme>
    </div>
  );
}

function PreAuthApp() {
  const auth = useAuth();
  const [cookies, setCookies] = useCookies(['auth'])

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Unable to authenticate user... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    setCookies('auth', auth.user?.access_token, { path: '/' })
    return (
      <App />
    );
  }

  auth.signinRedirect();
  return (<div>Displaying sign in redirect</div>);
}

export default PreAuthApp;
