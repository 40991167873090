import { createTheme, ThemeProvider } from '@mui/material/styles';

interface EntradeThemeProps {
  themeMode: 'light' | 'dark';
  children: React.ReactNode;
}

const EntradeTheme = (props: EntradeThemeProps) => {
  const { themeMode, children } = props;

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: '#228CC6',
        dark: '#1A6E9C',
        light: '#51A3D0',
      },
      ...(themeMode === 'light' 
        ? {
          background: {
            default: '#F6F7FA'
          },
          text: {
            primary: '#293045',
            secondary: '#858995',
            disabled: '#A2A5AE'
          }
        }
        : {
          
        }
      )
    },
    typography: {
      fontSize: 14,
      fontFamily: [
        "'Inter'",
        "Roboto",
        "sans-serif"
      ].join(),
      h1: {
        fontSize: '50px !important',
        fontWeight: '300 !important',
      },
      h2: {
        fontSize: '42px !important',
        fontWeight: '300 !important',
      },
      h3: {
        fontSize: '35px !important',
        fontWeight: '400 !important',
      },
      h4: {
        fontSize: '29px !important',
        fontWeight: '400 !important',
      },
      h5: {
        fontSize: '24px !important',
        fontWeight: '400 !important',
      },
      h6: {
        fontSize: '20px !important',
        fontWeight: '500 !important',
      },
      subtitle1: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
      },
      subtitle2: {
        fontSize: '14px !important',
        fontWeight: '600 !important',
      },
      body1: {
        fontSize: '14px !important',
        fontWeight: '400 !important',
      },
      body2: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
      },
      caption: {
        fontSize: '12px !important',
        fontWeight: '500 !important',
      },
    },
    components: {
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: '340px'
              }
            }
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  )
}

export default EntradeTheme;