import React, { useState, useEffect } from 'react'
import IIDentityResource from '../../interfaces/identityResources/IIdentityResource';
import useAxios from '../../hooks/useAxios';
import NewIdentityResourceDialog from './NewIdentityResourceDialog';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import PageHeader from '../../components/PageHeader/PageHeader';
import List from '../../components/List/List';
import ax from 'axios';
import { useNavigate } from 'react-router-dom';

function IdentityResources() {
  const [search, setSearch] = useState('');
  const [resources, setResources] = useState<IIDentityResource[]>([]);
  const [updateIdentity, setUpdateIdentity] = useState<IIDentityResource | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const axios = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await axios.get<IIDentityResource[]>('admin/IdentityResource');
      setResources(response.data);
    } catch (error) {
      if (ax.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
          return;
        }
      }
      console.log('an error has occurred while loading the data', error);
    }
  }

  function prepareNewIdentity() {
    setUpdateIdentity(undefined);
    setOpen(true);
  }

  function prepareUpdateIdentity(id: string) {
    setUpdateIdentity(resources.find(resource => resource.name === id));
    setOpen(true);
  }

  function handleUpdateIdentityResource(updatedResource: IIDentityResource) {
    const newState = resources.map(resource => {
      if (resource.name === updatedResource.name) {
        return updatedResource;
      } else {
        return resource;
      }
    });
    setResources(newState);
  }

  function sortByName(a: IIDentityResource, b: IIDentityResource) {
    if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
      return -1;
    } else if (b.name.toLocaleLowerCase() < a.name.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  }

  const filteredResources = React.useMemo(() => {
    const lowerCaseSearch = search.toLocaleLowerCase();
    return resources.filter(resource => search === '' ? true
      : resource.name.toLocaleLowerCase().includes(lowerCaseSearch)
      || resource.displayName?.toLocaleLowerCase().includes(lowerCaseSearch)
      || resource.description?.toLocaleLowerCase().includes(lowerCaseSearch)
    );
  }, [resources, search]);

  return (
    <>
      <NewIdentityResourceDialog
        open={open}
        onClose={() => setOpen(false)}
        updateIdentity={updateIdentity}
        onAdd={resource => setResources(prev => ([...prev].concat(resource)))}
        onUpdate={handleUpdateIdentityResource}
        onDelete={resource => setResources(prev => ([...prev].filter(a => a.name !== resource.name)))}
      />
      <Stack id='identity-resources-page' direction='column' spacing={2}>
        <PageHeader
          pageName='Identity Resources'
          searchValue={search}
          onSearchFieldChange={setSearch}
          button={{ buttonText: "Add Resource", handleButtonClick: prepareNewIdentity, startIcon: <AddIcon /> }}
        />
        <List
          listData={
            filteredResources.sort((a, b) => sortByName(a, b)).map(resource => ({
              title: resource.name,
              bodyText: resource.displayName ?? '',
              action: {
                type: 'primary',
                itemId: resource.name,
                actionText: 'Edit',
                actionHandler: prepareUpdateIdentity,
                variant: 'outlined'
              }
            }))
          }
        />
      </Stack>
    </>
  );
}

export default IdentityResources