import axios from "axios";
import { useAuth } from "react-oidc-context";

function useAxios() {
  const auth = useAuth();
  const axiosInstance = axios.create({
    baseURL: auth.settings.redirect_uri + '/api/',
    timeout: 5000,
    withCredentials: true
  });

  const token = auth.user?.access_token??'';
  axiosInstance.defaults.headers.common = {Authorization: `Bearer ${token}`}

  return axiosInstance;
}

export default useAxios;