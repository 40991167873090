import React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getToolbarRoutes } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const AppNav = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  height: '100%',
  transition: `width 200ms ease`,
  borderRight: `2px solid ${theme.palette.divider}`
}));

interface NavProps {
  expanded: boolean,
}

const Nav = (props: NavProps) => {
  const { expanded } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navWidth = expanded ? 264 : 56;
  const toolbarRoutes = getToolbarRoutes();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    route: string | undefined,
  ) => {
    if (route) {
      setSelectedIndex(index);
      navigate(route);
    }
  };

  const buildNavItems = () => toolbarRoutes.map((route, i) => (
    <ListItemButton
      selected={selectedIndex === i}
      onClick={(event) => handleNavItemClick(event, i, route.path)}
      key={route.dataSourceId}
    >
      <ListItemIcon>
        {route.icon}
      </ListItemIcon>
      <ListItemText sx={{ whiteSpace: 'nowrap', color: theme.palette.text.primary }} primary={route.text} />
    </ListItemButton>
  ))

  return (
    <AppNav sx={{ width: `${navWidth}px` }}>
      <List component="nav" aria-label="main mailbox folders">
        {buildNavItems()}
      </List>
    </AppNav>
  )
}

export default Nav;