import React from 'react'
import EnuitDialog from './EnuitDialog';

interface IConfirmDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

function ConfirmDeleteDialog(props: IConfirmDeleteDialogProps) {
  const {open, onClose, onDelete} = props;
  return (
    <EnuitDialog
      title='Confirm Deletion'
      open={open}
      onClose={onClose}
      text='This item will be permanently deleted. Would you like to continue?'
      onOkClick={onDelete} 
    />
  )
}

export default ConfirmDeleteDialog