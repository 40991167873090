import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import './dialog.scss';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

interface IEnuitDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  text?: string;
  /** The function called when the ok button is clicked. If no function is provided, onClose will be called instead
   * The provided function is expected to handle closing the dialog. This will enable validation to be run on ok click, 
   * and the dialog can remain open if the validation fails.
  */
  onOkClick?: () => void;
  hideOkButton?: boolean;
  children?: React.ReactNode;
  showDelete?: boolean;
  onDeleteClick?: () => void;
}

function EnuitDialog(props: IEnuitDialogProps) {
  const { open, onClose, title, onOkClick, text, children, showDelete, onDeleteClick, hideOkButton } = props;
  const [shouldDelete, setShouldDelete] = useState(false);
  const showOkButton = !hideOkButton??true;

  useEffect(() => {
    setShouldDelete(false);
  }, [open])

  const EnuitDialogTitle = () => {
    return (
      <DialogTitle sx={{ m: 0, p: 2, borderBottom: 1, borderColor: grey[500] }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <EnuitDialogTitle />
      <DialogContent className='dialog-content'>
        {text !== undefined && 
          <DialogContentText>
            {text}
          </DialogContentText>
        }
        {children}
      </DialogContent>
      <DialogActions>
      
        <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
          <Stack direction='row' spacing={1}>
            {showDelete && !shouldDelete && <Button onClick={() => setShouldDelete(prev => !prev)} variant='outlined' color='error'>Delete</Button>}
            {showDelete && shouldDelete && <Button onClick={onDeleteClick} variant='contained' color='error'>Yes, Delete</Button>}
            {showDelete && shouldDelete && <Typography color='error' sx={{alignSelf: 'center'}}>Delete this forever?</Typography>}
          </Stack>
          {showOkButton && <Button onClick={onOkClick ?? onClose} variant='contained'>Ok</Button>}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default EnuitDialog