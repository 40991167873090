import React from 'react'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Searchfield from "../Searchfield/Searchfield"


interface PageHeaderProps {
  pageName: string,
  searchValue: string,
  onSearchFieldChange: (searchString: string) => void,
  button: {
    buttonText: string,
    handleButtonClick: () => void,
    startIcon?: React.ReactNode
  }
}

const PageHeader = (props: PageHeaderProps) => {
  const { pageName, searchValue, onSearchFieldChange, button } = props;

  return (
    <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
      <Typography variant="subtitle1">{pageName}</Typography>
      <Stack direction='row'>
        <Searchfield
          value={searchValue}
          onChange={(e) => onSearchFieldChange(e.target.value)}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          onClick={() => button.handleButtonClick()}
          startIcon={button.startIcon}
        >
          {button.buttonText}
        </Button>
      </Stack>
    </Stack>
  )
}

export default PageHeader;