import React, { useState, useEffect } from 'react';
import IUser from '../../interfaces/users/IUser';
import useAxios from '../../hooks/useAxios';
import _ from 'lodash';
// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import SaveIcon from '@mui/icons-material/Save';
// Interface
import IRole from '../../interfaces/roles/IRole';
import IClaim from '../../interfaces/claims/IClaim';
import IUserClaim from '../../interfaces/users/IUserClaim';
import IClient from '../../interfaces/clients/IClient';
//
import NewUserRoleDialog from './dialogs/NewUserRoleDialog';
import NewUserClaimDialog from './dialogs/NewUserClaimDialog';
import UserInfoCard from './localComponents/UserInfoCard';
import NewUserClientDialog from './dialogs/NewUserClientDialog';
import ConfirmDeleteDialog from '../../components/dialog/ConfirmDeleteDialog';
import { useAuth } from 'react-oidc-context';
import Box from '@mui/material/Box';
import ResetUserPasswordDialog from './dialogs/ResetUserPasswordDialog';
import { Alert, Snackbar } from '@mui/material';

interface ISingleUserProps {
  user: IUser
  onUpdateUser: (updatedUser: IUser) => void;
}

function SingleUser(props: ISingleUserProps) {
  const { user, onUpdateUser } = props;
  const [updateUser, setUpdateUser] = useState<IUser>(user);
  const [userRoles, setUserRoles] = useState<IRole[]>([]);
  const [userClaims, setUserClaims] = useState<IUserClaim[]>([]);
  const [userClients, setUserClients] = useState<IClient[]>([]);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [claims, setClaims] = useState<IClaim[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [claimDialogOpen, setClaimDialogOpen] = useState(false);
  const [clientDialogOpen, setClientDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [passwordResetOpen, setPasswordResetOpen] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const idToDelete = React.useRef('');
  const deleteFunction = React.useRef<() => void>(() => null);
  const snackbarMessage = React.useRef('');
  const auth = useAuth();
  const axios = useAxios();

  const userChanged = React.useMemo(() => {
    return !_.isEqual(user, updateUser);
  }, [updateUser, user]);



  //#region LoadData

  useEffect(() => {
    loadClaims();
    loadClients();
    loadRoles();
    loadUserClients();
    loadUserRoles();
    loadUserClaims();
  }, []);

  const loadUserClaims = async () => {
    try {
      const response = await axios.get<IUserClaim[]>('admin/User/Claim?userId=' + user.id);
      setUserClaims(response.data);
    } catch (error) {

    }
  }

  const loadUserRoles = async () => {
    try {
      const response = await axios.get<IRole[]>('admin/User/Role?userId=' + user.id);
      setUserRoles(response.data);
    } catch (error) {

    }
  }

  const loadUserClients = async () => {
    try {
      const response = await axios.get<IClient[]>('admin/User/Client?userId=' + user.id);
      setUserClients(response.data);
    } catch (error) {

    }
  }

  const loadRoles = async () => {
    try {
      const response = await axios.get<IRole[]>('admin/Role');
      setRoles(response.data);
    } catch (error) {
      console.log('an error has occurred while loading the data', error);
    }
  }

  const loadClients = async () => {
    try {
      const response = await axios.get<IClient[]>('admin/Client');
      setClients(response.data);
    } catch (error) {

    }
  }

  const loadClaims = async () => {
    try {
      const response = await axios.get<IClaim[]>('admin/ClaimType');
      setClaims(response.data);
    } catch (error) {
      console.log('an error has occurred while loading the data', error);
    }
  }

  //#endregion

  function calcAvailableUserRoles() {
    const currentUserRoleIds = userRoles.map(role => role.name);
    const availableRoles = roles.filter(role => !currentUserRoleIds.includes(role.name))
    return availableRoles;
  }
  const getAvailableUserRoles = React.useCallback(calcAvailableUserRoles, [roles, userRoles]);

  function calcAvailableUserClaims() {
    const currentUserClaimIds = userClaims.map(claim => claim.type);
    const availableClaims = claims.filter(claim => !currentUserClaimIds.includes(claim.type));
    return availableClaims;
  }
  const getAvailableUserClaims = React.useCallback(calcAvailableUserClaims, [claims, userClaims]);

  function calcAvailableUserClients() {
    const currentUserClientIds = userClients.map(client => client.clientId);
    const availableClients = clients.filter(client => !currentUserClientIds.includes(client.clientId));
    return availableClients;
  }
  const getAvailableUserClients = React.useCallback(calcAvailableUserClients, [clients, userClients]);

  useEffect(() => {
    setUpdateUser(user);
  }, [user])

  function handleUpdateUser(updates: Partial<IUser>) {
    const updatedUser: IUser = {
      ...updateUser,
      ...updates
    }

    setUpdateUser(updatedUser);
  }

  const handleSaveUpdatedUser = async () => {
    try {
      await axios.put<IUser>('admin/User?userId=' + user.id, { ...updateUser });

      onUpdateUser(updateUser);
    } catch (error) {
      console.log('An error occurred while trying to update the user', error);
    }
  }

  const handleDeleteUserRole = async () => {
    try {
      await axios.delete(`admin/User/Role?userId=${user.id}&role=${idToDelete.current}`);
      const newState = userRoles.filter(role => role.name !== idToDelete.current);
      setUserRoles(newState);
    } catch (error) {
      console.log('an error has occurred while deleting the user role', error);
    }
  }

  const handleDeleteUserClaim = async () => {
    try {
      await axios.delete(`admin/User/Claim?userId=${user.id}&claimType=${idToDelete.current}`);
      const newState = userClaims.filter(claim => claim.type !== idToDelete.current);
      setUserClaims(newState);
    } catch (error) {
      console.log('an error has occurred while deleting the user claim', error);
    }
  }


  const handleDeleteUserClient = async () => {
    try {
      const clientToDelete = userClients.find(client => client.clientId === idToDelete.current);
      await axios.delete<IClient>('admin/User/Client?userId=' + user.id, { data: { ...clientToDelete } });
      const newState = userClients.filter(client => client.clientId !== idToDelete.current);
      setUserClients(newState);
    } catch (error) {
      console.log('an error has occurred while deleting the user client', error);
    }
  }

  const handleEmailUpdate = (value: string) => {
    const isValidEmail = value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!isValidEmail) {
      setEmailErrorMessage('Must be a valid email');
    } else {
      setEmailErrorMessage('');
    }

    handleUpdateUser({ email: value === '' ? null : value });
  }

  const isSaveAllowed = React.useMemo(() => {
    if (emailErrorMessage !== '') {
      console.log('save not allowed, invalid email')
      return false;
    }

    return userChanged;
  }, [userChanged, emailErrorMessage]);

  const handleUserPasswordResetClose = () => {
    snackbarMessage.current = 'Password reset successfully';
    setPasswordResetOpen(false);
    setSnackbarOpen(true);
  }

  const handleConfirmEmail = async () => {
    try {
      const response = await axios.post('admin/User/ConfirmEmail', {...user});
      onUpdateUser({...user, emailConfirmed: true});
    } catch (error) {
      console.log('An error occurred while confirming the user email', error);
    }
  }

  return (
    <div>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        onDelete={() => { deleteFunction.current(); setConfirmDeleteOpen(false); }}
      />
      <ResetUserPasswordDialog 
        open={passwordResetOpen}
        onClose={handleUserPasswordResetClose}
        user={user}
      />
      <NewUserRoleDialog
        open={roleDialogOpen}
        onClose={() => setRoleDialogOpen(false)}
        roles={getAvailableUserRoles()}
        userId={user.id}
        onAdd={role => setUserRoles(prev => [...prev].concat(role))}
      />
      <NewUserClaimDialog
        open={claimDialogOpen}
        onClose={() => setClaimDialogOpen(false)}
        claims={getAvailableUserClaims()}
        userId={user.id}
        onAdd={claim => setUserClaims(prev => [...prev].concat(claim))}
      />
      <NewUserClientDialog
        open={clientDialogOpen}
        onClose={() => setClientDialogOpen(false)}
        clients={getAvailableUserClients()}
        userId={user.id}
        onAdd={client => setUserClients(prev => [...prev].concat(client))}
      />
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} autoHideDuration={3000}>
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          {snackbarMessage.current}
        </Alert>
      </Snackbar>
      <Grid container spacing={2} >
        <Grid xs={12} md={6}>
          <Stack direction='column' spacing={2}>
            <Paper variant='outlined' sx={{ p: 4 }}>
              <Stack direction='column' spacing={2}>
                <Typography variant='subtitle1'>User Information</Typography>
                <TextField
                  variant='outlined'
                  value={updateUser.name}
                  onChange={((e) => handleUpdateUser({ name: e.target.value }))}
                  label='Name'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PersonIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  variant='outlined'
                  value={updateUser.userName}
                  disabled
                  label='Username'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PermContactCalendarIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <div>
                  <Stack justifyContent='space-between' direction='row' spacing={1}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      value={updateUser.email || ''}
                      onChange={e => handleEmailUpdate(e.target.value)}
                      label='Email'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <AlternateEmailIcon />
                          </InputAdornment>
                        )
                      }}
                      helperText={emailErrorMessage}
                      error={emailErrorMessage !== ''}
                    />
                    {!updateUser.emailConfirmed && <Button onClick={handleConfirmEmail} variant='outlined'>Confirm</Button>}
                  </Stack>
                  {updateUser.emailConfirmed
                    ?
                    <Typography sx={{ pl: 2 }} color='success.main' variant='body2'>{<CheckCircleIcon color='inherit' fontSize='inherit' />} Confirmed</Typography>
                    :
                    <Typography sx={{ pl: 2 }} color='error.main' variant='body2'>{<CancelIcon color='inherit' fontSize='inherit' />} Not Confirmed</Typography>
                  }
                </div>
                <TextField
                  variant='outlined'
                  value={updateUser.phoneNumber || ''}
                  onChange={((e) => handleUpdateUser({ phoneNumber: e.target.value === '' ? null : e.target.value }))}
                  label='Phone Number'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PhoneIphoneIcon />
                      </InputAdornment>
                    )
                  }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
                {!updateUser.isInternal && <TextField 
                  variant='outlined'
                  value={updateUser.objectIdentifierId}
                  label='Object Identifier'
                  disabled
                />}
                {!updateUser.isInternal && <TextField 
                  variant='outlined'
                  value={updateUser.providerDisplayName}
                  label='Provider'
                  disabled
                />}
                <Stack direction='row' justifyContent='space-between'>
                  <Box>
                    <FormControlLabel
                      label='Account Locked Out'
                      control={
                        <Switch
                          disabled={updateUser.id === auth.user?.profile.sub}
                          checked={updateUser.isLockedOut}
                          value={updateUser.isLockedOut}
                          onChange={(e) => handleUpdateUser({ isLockedOut: e.target.checked })}
                        />
                      }
                    />
                    <Button disabled={!user.isInternal} variant='outlined' onClick={() => setPasswordResetOpen(true)}>Reset password</Button>
                  </Box>
                  <Button
                    variant='contained'
                    sx={userChanged ? {} : { display: 'none' }}
                    disabled={!isSaveAllowed}
                    onClick={handleSaveUpdatedUser}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Paper>
            <Paper variant='outlined' >
              <Stack direction='column' spacing={1} sx={{ p: 4 }}>
                <Typography variant='subtitle1'>User Roles</Typography>
                {userRoles.map(role => (
                  <UserInfoCard
                    key={role.name}
                    primaryKey={role.name}
                    primaryText={role.name}
                    secondaryText={role.description}
                    onDelete={() => {
                      idToDelete.current = role.name;
                      deleteFunction.current = handleDeleteUserRole;
                      setConfirmDeleteOpen(true)
                    }}
                  />
                ))}
                <span>
                  <Button onClick={() => setRoleDialogOpen(true)} variant='contained' startIcon={<AddIcon />}>Add Role</Button>
                </span>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack direction='column' spacing={2}>
            <Paper variant='outlined' sx={{ p: 4 }}>
              <Stack direction='column' spacing={1}>
                <Typography variant='subtitle1'>Clients</Typography>
                {userClients.map(client => (
                  <UserInfoCard
                    key={client.clientId}
                    primaryKey={client.clientId}
                    primaryText={client.clientId}
                    secondaryText={client.clientName ?? ''}
                    onDelete={() => {
                      idToDelete.current = client.clientId;
                      deleteFunction.current = handleDeleteUserClient;
                      setConfirmDeleteOpen(true);
                    }}
                  />
                ))}
                <span>
                  <Button onClick={() => setClientDialogOpen(true)} variant='contained' startIcon={<AddIcon />}>Add Client</Button>
                </span>
              </Stack>
            </Paper>
            <Paper variant='outlined' sx={{ p: 4 }}>
              <Stack direction='column' spacing={1}>
                <Typography variant='subtitle1'>User Claims</Typography>
                {userClaims.map(claim => (
                  <UserInfoCard
                    key={claim.type}
                    primaryKey={claim.type}
                    primaryText={claim.type}
                    secondaryText={claim.value}
                    onDelete={() => {
                      idToDelete.current = claim.type;
                      deleteFunction.current = handleDeleteUserClaim;
                      setConfirmDeleteOpen(true);
                    }}
                  />
                ))}
                <span>
                  <Button onClick={() => setClaimDialogOpen(true)} variant='contained' startIcon={<AddIcon />}>Add Claim</Button>
                </span>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default SingleUser