import React from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import type { NavigateOptions } from 'react-router-dom';

export interface IHeaderQueryParams {
  includeInactive: boolean;
  searchFilter: string;
}

export const userIdQueryParamKey = 'usersPage'

export interface IUserSearchParams {
  selectedUserId: string;
  filterClientId: string;
}

export function generateQueryParams<T>(key: string, params: T): URLSearchParams {
  //use empty string so params aren't concatenated onto existing params
  const searchParams = createSearchParams('');
  searchParams.set(key, JSON.stringify(params));
  return searchParams;
}

//code from react router custom query parsing example
// React router uses this in their example but it seems to work fine
//  using my generateQueryParams method. Maybe it will error with multiple
//  keys but it needs more testing
// const newSearchParams = new URLSearchParams(searchParams);
// newSearchParams.set(key, JSON.stringify(newValue));
export function useQueryParams<T>(key: string): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  //Is there a better way to handle this than passing in empty json object?
  const value = React.useMemo(() => JSON.parse(paramValue !== null ? paramValue : '{}'), [paramValue]);

  const setValue = React.useCallback(
    (newValue: T, options?: NavigateOptions) => {
      const newSearchParams = generateQueryParams(key, newValue);
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue];
}
