import React, { useState, useEffect } from 'react'
import EnuitDialog from '../../../components/dialog/EnuitDialog'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import IClaim from '../../../interfaces/claims/IClaim';
import IUserClaim from '../../../interfaces/users/IUserClaim';
import useAxios from '../../../hooks/useAxios';

interface INewUserClaimsProps {
  open: boolean;
  onClose: () => void;
  userId: string;
  claims: IClaim[];
  onAdd: (newUserClaim: IUserClaim) => void;
}

function NewUserClaimDialog(props: INewUserClaimsProps) {
  const { open, onClose, userId, claims, onAdd } = props;
  const [error, setError] = useState(false);
  const [newClaimType, setNewClaimType] = useState('');
  const [newClaimValue, setNewClaimValue] = useState('');
  const axios = useAxios();

  useEffect(() => {
    setNewClaimType('');
    setNewClaimValue('');
    setError(false);
  }, [open]);

  const handleOkClick = async () => {
    if (newClaimType === '' || newClaimValue === '') {
      setError(true);
      return;
    }

    try {
      const newUserClaim = { type: newClaimType, value: newClaimValue };
      const response = await axios.post<IUserClaim>('admin/User/Claim?userId=' + userId, { ...newUserClaim });

      setNewClaimType('');
      setNewClaimValue('');
      onAdd(newUserClaim);
    } catch (error) {
      console.log('An error occurred while adding a new user claim', error);
    }

    onClose();
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='New User Claim'
      onOkClick={handleOkClick}
    >
      <FormControl fullWidth size='small' error={error && newClaimType === ''}>
        <Typography variant='body2'>Claim Type</Typography>
        <Select
          value={newClaimType}
          onChange={(e) => setNewClaimType(e.target.value)}
        >
          {claims.map(claim => (
            //For the key, currently there are some claims with null types and descriptions, not sure if error or not
            <MenuItem key={claim.type ?? claim.e7ClaimTypeId} value={claim.type}>{claim.description} - {claim.type}</MenuItem>
          ))}
        </Select>
        {error && newClaimType === '' && <FormHelperText>Claim Type is required</FormHelperText>}
        <Typography variant='body2' sx={{ marginTop: '16px' }}>Value</Typography>
        <TextField
          error={error && newClaimValue === ''}
          helperText={error && newClaimValue === '' ? 'Value is required' : ''}
          size='small'
          value={newClaimValue}
          onChange={(e) => setNewClaimValue(e.target.value)}
        />
      </FormControl>
    </EnuitDialog>
  );
}

export default NewUserClaimDialog