import React, { useEffect, useState } from 'react';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import { IClientProvider } from '../../../interfaces/clients/IClient';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type NewClientProviderDialogProps = {
  open: boolean;
  onClose: () => void;
  onAdd: (provider: IClientProvider, selected: boolean) => void;
  clientId: string;
  providers: IClientProvider[];
}

function NewClientProviderDialog(props: NewClientProviderDialogProps) {
  const { open, onClose, onAdd, clientId, providers } = props;
  const [newProviders, setNewProviders] = useState<IClientProvider[]>([]);

  useEffect(() => {
    setNewProviders([]);
  }, [open])

  const handleOkClick = () => {
    for (const provider of newProviders) {
      onAdd(provider, true);
    }

    onClose();
  }

  return (
    <EnuitDialog
      title='New Client Provider'
      open={open}
      onClose={onClose}
      onOkClick={handleOkClick}
    >
      <Typography>Provider</Typography>
      <Autocomplete
        value={newProviders}
        onChange={(e, value, r, d) => setNewProviders(value)}
        options={providers}
        renderInput={params => <TextField {...params} />}
        size='small'
        multiple
        getOptionLabel={provider => provider.displayName}
        isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
      />
    </EnuitDialog>
  )
}

export default NewClientProviderDialog