import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

export interface ISearchfieldProps extends OutlinedInputProps {}

const Searchfield = (props: ISearchfieldProps) => (
  <OutlinedInput 
    {...props}
    startAdornment={<SearchIcon sx={{opacity: 0.5}} />} 
    placeholder={props.placeholder ?? "Search records" }
    size="small"
  />
)

export default Searchfield;
